<template>
  <div id="page-school-edit">

    <vs-alert color="danger" title="School Not Found" :active.sync="school_not_found">
      <span>Escuela con id {{ $route.params.idSchool }} no encontrada. </span>
      <span>
        <span>Ir a </span><router-link to="/institution/schools" class="text-inherit underline">Escuelas</router-link>
      </span>
    </vs-alert>

    <vx-card>

      <div v-if="!school_not_found" slot="no-body" class="tabs-container px-6 pt-6">
        <h5 class="my-3">{{!$route.params.idSchool ? 'Agregar Nueva' : 'Editar'}} Escuela</h5>
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Información Básica" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <school-add-edit-tab-information class="mt-4" :data="school_data" @showNotify="showNotify" />
            </div>
          </vs-tab>
          <!-- <vs-tab label="Garantía de Pago">
            <div class="tab-text">
              <user-edit-tab-information class="mt-4" :data="school_data" />
            </div>
          </vs-tab> -->
          <!-- <vs-tab label="Cuenta Bancaria">
            <div class="tab-text">
              <user-edit-tab-social class="mt-4" :data="school_data" />
            </div>
          </vs-tab> -->
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import SchoolAddEditTabInformation     from "./SchoolAddEditTabInformation.vue"
// import SchoolAddEditTabServices from "./SchoolAddEditTabServices.vue"
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"
// import UserEditTabSocial      from "./SchoolAddEditTabBank.vue"

// Store Module


export default {
  components: {
    SchoolAddEditTabInformation,
    // SchoolAddEditTabServices,
    // UserEditTabSocial,
  },
  data() {
    return {
      school_data: {},
      school_not_found: false,

      /*
        This property is created for fetching latest data from API when tab is changed

        Please check it's watcher
      */
      activeTab: 0,
    }
  },
  watch: {
    activeTab() {
      // this.fetch_school_data(this.$route.params.userId)
    }
  },
  methods: {
    fetch_data(id) {
      const school = this.$store.getters['dataListInstitutions/getSchoolById'](id)
      if (!school)
        this.school_not_found = true

      this.school_data = school
    },
    showNotify(type,title,text) {
      this.$vs.notify({
        color: type || 'success',
        title: title || 'Correcto!',
        text: text || '',
        time: 8000,
        position: 'top-center',
      })
    },
  },
  created() {
    // Register Module UserManagement Module
    if(!moduleDataListInstitutions.isRegistered) {
      this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
      moduleDataListInstitutions.isRegistered = true
    }
    if (this.$route.params.idSchool)
      this.fetch_data(this.$route.params.idSchool)
  }
}

</script>

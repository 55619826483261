<template>
  <div id="school-add-edit-tab-info">
    <!-- Avatar Row -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex items-start flex-col sm:flex-row">
          <img v-if="dataImage" :src="image_loaded ? dataImage : imgPath + dataImage" class="mr-8 rounded h-24 w-24" />
          <div v-else class="mr-8 rounded h-24 w-24 primary flex items-center flex-col justify-center" style="background-color: #f5f5f5"><feather-icon icon="ImageIcon" style="color:lightgray"></feather-icon></div>
          <span class="text-danger text-sm" v-show="errors.has('school_image')">{{ errors.first('school_image') }}</span>
          <div>
            <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">{{ dataName }}</p>
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">

            <!-- Toggle comment of below buttons as one for actual flow & currently shown is only for demo -->
            <vs-button class="mr-4 mb-4" @click="$refs.updateImgInput.click()">Actualizar Imagen</vs-button>
            <vs-button type="border" color="danger" v-if="image_loaded" @click="removeImage">Remover Imagen</vs-button>
          </div>
        </div>
        <vs-alert v-show="image_error" color="danger" style="height: initial" icon-pack="feather" icon="icon-info">
          {{ image_error }}
        </vs-alert>
      </div>
    </div>

    <!-- Content Row -->
    <div class="vx-row mt-4">
      <div class="vx-col md:w-1/2 w-full">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="InfoIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Datos Generales</span>
          </div>
          <vs-divider />
        </div>
        <!-- SCHOOL NAME -->
        <vs-input class="w-full mt-4" label="Nombre de la Escuela" v-model="dataName" v-validate="'required|alpha_spaces'" data-vv-as="Nombre de la Escuela" name="school_name" />
        <span class="text-danger text-sm"  v-show="errors.has('school_name')">{{ errors.first('school_name') }}</span>

        <!-- SCHOOL ADMIN -->
        <div class="mt-4">
          <label class="vs-input--label">Administrador de la Escuela</label>
          <v-select multiple :closeOnSelect="false" v-model="dataIdCollector" :clearable="false" :options="ownerOptions" :reduce="dataIdCollector => dataIdCollector.value" v-validate="'required'" data-vv-as="Administrador" name="id_collector" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('id_collector')">{{ errors.first('id_collector') }}</span>
        </div>

        <!-- COMMISSION -->
        <div class="mt-4 w-full">
          <label class="vs-input--label">Comisión Tiptap</label>
          <div class="flex flex-wrap">
            <vs-input-number @input="checkMinValue" label="%" v-model="dataTiptapCommission" :step="0.125" v-validate="'min:0|min_value:0'" data-vv-as="Comisión Tiptap" name="tiptap_commission" class="w-100"/>
            <span class="text-danger text-sm w-full"  v-show="errors.has('tiptap_commission')">{{ errors.first('tiptap_commission') }}</span>
          </div>
        </div>

        <div class="mt-4 flex flex-wrap">
          <div class="w-1/2">
            <vs-input class="w-full" label="Dirección" v-model="dataAddress" name="address" autocomplete="off" v-validate="'required'" data-vv-as="Dirección"/>
            <span class="text-danger text-sm"  v-show="errors.has('address')">{{ errors.first('address') }}</span>
          </div>
          <div class="w-1/4 pl-4">
            <vs-input class="w-full" label="Ciudad" v-model="dataCity" name="city" placeholder="Ciudad" autocomplete="off" v-validate="'required'" data-vv-as="Ciudad"/>
            <span class="text-danger text-sm"  v-show="errors.has('city')">{{ errors.first('city') }}</span>
          </div>
          <div class="w-1/4 pl-4">
            <vs-input class="w-full" label="Código Postal" v-model="dataZipcode" name="zip_code" placeholder="ZipCode" autocomplete="off" v-validate="'required'" data-vv-as="Código Postal"/>
            <span class="text-danger text-sm"  v-show="errors.has('zip_code')">{{ errors.first('zip_code') }}</span>
          </div>
        </div>
        <!-- <vs-input class="w-full mt-4" label="Email" v-model="data_local.email" type="email" v-validate="'required|email'" name="email" />
        <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span> -->
      </div>

      <div class="vx-col md:w-1/2 w-full">

        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Cuenta Bancaria</span>
          </div>
          <vs-divider />
        </div>

        <div class="mt-4">
          <vs-input class="w-full mt-4" label="Banco" v-model="dataBankName" v-validate="'required'" data-vv-as="Banco" name="bank_name" autocomplete="off"/>
          <span class="text-danger text-sm"  v-show="errors.has('bank_name')">{{ errors.first('bank_name') }}</span>
        </div>

        <div class="mt-4">
          <label style="font-size:.85rem">Tipo de Cuenta</label>
          <div class="flex flex-wrap mt-3 mb-6">
            <vs-radio v-model="dataAccountType" :vs-value="0" class="mr-4">Caja de Ahorro</vs-radio>
            <vs-radio v-model="dataAccountType" :vs-value="1" class="mr-4">Cuenta Corriente</vs-radio>
          </div>
        </div>

        <div class="mt-4">
          <vs-input class="w-full mt-4" label="Nombre de la Cuenta" v-model="dataAccountName" v-validate="'required'" data-vv-as="Nombre de la Cuenta" name="account_name" autocomplete="off"/>
          <span class="text-danger text-sm"  v-show="errors.has('account_name')">{{ errors.first('account_name') }}</span>
        </div>

        <div class="mt-4">
          <vs-input class="w-full mt-4" label="Número de Cuenta" v-model="dataAccountNumber" v-validate="'required'" data-vv-as="Número de Cuenta" name="bank_account" autocomplete="off"/>
          <span class="text-danger text-sm"  v-show="errors.has('bank_account')">{{ errors.first('bank_account') }}</span>
        </div>

      </div>

    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm">{{ dataId ? 'Editar' : 'Agregar'}}</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data">Cancelar</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"

export default {
  components: {
    vSelect
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {


      imgPath: this.$baseUrlImg,
      image_loaded: false,
      image_error: '',
      image_settings: {
        size: '',
        width: '',
        height: ''
      },

      //data
      data_local: {},
      dataId: null,
      dataName: null,
      dataIdCollector: [],
      dataTiptapCommission: null,
      dataAddress: null,
      dataCity: null,
      dataZipcode: null,
      dataBankId: null,
      dataBankName: null,
      dataAccountType: 0,
      dataAccountName: null,
      dataAccountNumber: null,
      dataImage: null,
      dataCollectors: [],

      owner_local: '',

      ownerOptions: [],
      statusOptions: [
        { label: "Active",  value: "active" },
        { label: "Blocked",  value: "blocked" },
        { label: "Deactivated",  value: "deactivated" },
      ],
      roleOptions: [
        { label: "Admin",  value: "admin" },
        { label: "User",  value: "user" },
        { label: "Staff",  value: "staff" },
      ],
    }
  },
  computed: {
    status_local: {
      get() {
        return this.data_local.status
      },
      set(obj) {
        this.data_local.status = obj.value
      }
    },
    role_local: {
      get() {
        return this.data_local.role
      },
      set(obj) {
        this.data_local.role = obj.value
      }
    },
    validateForm() {
      // this.$validator.validateAll()
      return !this.errors.any()
    }
  },
  methods: {
    capitalize(str) {
      return str.slice(0,1).toUpperCase() + str.slice(1,str.length)
    },
    save_changes() {
      this.$validator.validateAll().then(() => {
          if(!this.validateForm) return

          let obj = {
              "school": {
                "id_school": this.dataId,
                "name": this.dataName,
                "tiptap_comission": this.dataTiptapCommission,
                "id_school_type": 1,
                "address": this.dataAddress || '',
                "city": this.dataCity || '',
                "zip_code": this.dataZipcode || '',
                "image": (this.image_loaded) ? this.dataImage : '',
              },
              "bank_account": {
                "id_bank": this.dataBankId,
                "bank_name": this.dataBankName || '',
                "account_type": this.dataAccountType || 0,
                "account_name": this.dataAccountName || '',
                "account_number": this.dataAccountNumber || ''
              },
              "collectors": {
                "id_account": this.dataIdCollector,
                "id_school": this.dataCollectors[0] ? this.dataCollectors[0].id_school :  null
              }
          }
          // "id_school_academic_period": null,

          let action = "updateSchool"

          if (!this.dataId) {
            const { bank_account, school } = obj
            delete obj.id_school
            delete obj.bank_account
            delete obj.school
            delete obj.collectors
            obj = {...school, ...bank_account}
            obj.id_collector = this.dataIdCollector
            action = "addSchool"
          }

          this.$store.dispatch(`dataListInstitutions/${action}`, obj)
          .then(response => {
            if(response.data.status) {
              this.$emit("showNotify", 'success', null, 'Se han guardado los cambios.')
              this.$router.push('/institution/schools')
            } else {
              this.$emit("showNotify", 'danger', 'Error', response.data.msg)
            }
          })
          .catch(err => {
            this.$emit("showNotify", 'danger', 'Error', err)
          }
          )
      })



    },
    reset_data() {
      //this.initValues()
      this.$router.push('/institution/schools')
    },
    initValues() {
      this.image_loaded = false
      const { id, name, address, city, zip_code, tiptap_comission, bank, image, collectors } = JSON.parse(JSON.stringify(this.data))
      this.dataId = id
      this.dataName = name
      this.dataIdCollector = collectors.map(c => c.id_account)
      this.dataTiptapCommission = tiptap_comission
      this.dataAddress = address
      this.dataCity = city
      this.dataZipcode = zip_code
      this.dataBankId = bank.id
      this.dataBankName = bank.bank_name
      this.dataAccountType = bank.account_type
      this.dataAccountName = bank.account_name
      this.dataAccountNumber = bank.account_number
      this.dataImage = image
      this.dataCollectors = collectors
    },
    updateCurrImg(input) {
      this.image_loaded = false;
      this.image_error = '';
      if (input.target.files && input.target.files[0]) {
        this.image_settings.size = input.target.files[0].size;
        var reader = new FileReader()
        reader.onload = e => {
          let img = new Image();
          img.onload = () => {
            this.image_settings.width = img.width;
            this.image_settings.height = img.height;
            if (this.image_settings.width != this.image_settings.height || this.image_settings.width > 300 ) {
              this.image_error = 'La imágen debe ser cuadrada (misma altura y ancho) con un máximo de 300px';
            } else {
              if (this.image_settings.size > 100000){
                this.image_error = 'La imágen excede el tamaño máximo (100 KB).';
              } else {
                this.dataImage = e.target.result
                this.image_loaded = true;
              }
            }
          }
          img.src = e.target.result;
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    removeImage() {
      this.dataImage = ''
      this.image_loaded = false
    },
    getOwnerChoices() {
      if(this.owner_choices && this.owner_choices.length) return

      const owners = this.$store.getters['userManagement/getAvailableCollectors'](this.dataIdCollector);
      owners.forEach(element => {
        this.ownerOptions.push({label: element.full_name, value: element.id})
      });
    },
    checkMinValue(val) {
      if (val < 0 || isNaN(val)) {
        this.dataTiptapCommission = 0
      }
    }
  },
  created() {
    // Register Module UserManagement Module
    if(!moduleDataListInstitutions.isRegistered) {
      this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
      moduleDataListInstitutions.isRegistered = true
    }
    this.data_local = JSON.parse(JSON.stringify(this.data))

    if(Object.keys(this.data_local).length > 0 ) {
      this.initValues()
    }

    this.getOwnerChoices()
  }
}
</script>
<style>
#school-add-edit-tab-info ul.vs__dropdown-menu {
  max-height: 220px;
}
</style>
